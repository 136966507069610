import React from "react"
import PropTypes from 'prop-types'
import Page from "../components/Page"
import data from '../data/program'

const ProgramPage = ({ pageContext }) => (
  <>
    <Page
      name="Program"
      language={pageContext.language}
      data={data}
    />
  </>
)

ProgramPage.propTypes = {
  pageContext: PropTypes.shape({
    language: PropTypes.string.isRequired,
  }),
}

export default ProgramPage