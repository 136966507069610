import menu from "./menu";
import meta from "./meta";
import footer from "./footer";
import speakers from "./speakers";

function speaker(slug) {
  const { name, role, url } = speakers.find((speaker) => speaker.slug === slug);
  const hreftext = url.replace(/(^\w+:|^)\/\//, "").replace(/\/$/, "");
  return {
    name: name,
    description: role,
    href: url,
    hreftext: hreftext,
  };
}

const networking = {
  es: "Networking Puesta de sol",
  en: "Networking",
  ca: "Networking Posta de sol",
};

const data = {
  menu,
  meta,
  components: [
    {
      name: "Wrapper",
      components: [
        {
          name: "Program",
          props: {
            hero: {
              title: {
                es: "Programa Formentera20 2024",
                en: "Formentera20 Program 2024",
                ca: "Programa Formentera20 2024",
              },
              description: {
                es:
                  "Transformación digital. Comunicación. Creatividad. Redes Sociales. Marketing digital. Branding. Diseño. Big data. Contenidos. Plataformas digitales. Podcast… <br><br> No te pierdas esta experiencia digital y natural. ¡Vívela!",
                en:
                  "Digital transformation. Communication. Creativity. Social networks. Digital marketing. Branding. Design. Big data. Contents. Digital platforms. Podcast… <br><br>Do not miss this digital and natural experience.",
                ca:
                  "Transformació digital. Comunicació. Creativitat. Xarxes Socials. Màrqueting digital. Branding. Disseny. Big data. Continguts. Plataformes digitals. Podcast… <br><br> No et perdis aquesta experiència digital i natural. Viu-la!",
              },
              image: "/schedule/1.jpg",
            },
            items: [
              {
                date: {
                  es: "Jueves, 16 de mayo",
                  en: "Thursday, May 16th",
                  ca: "Dijous, 16 de maig",
                },
                subitems: [
                  {
                    hour: "16:00h",
                    id: "subitem1",
                    name: {
                      es: "Inauguración / Bienvenida",
                      en: "Opening",
                      ca: "Inauguració / Benvinguda",
                    },
                    speakers: [
                      {
                        name: "Rosa Castells",
                        description: {
                          es: "Dtra. & Creadora Formentera20",
                          en: "Formentera20 Founder",
                          ca: "Dtra. & Creadora Formentera20",
                        },
                        href: "https://rosacastells.net/",
                        hreftext: "www.rosacastells.net",
                      },
                    ],
                  },
                  {
                    hour: "16:15h",
                    id: "subitem2",
                    name: {
                      es: "Talks that matters",
                      en: "Talks that matters",
                      ca: "Talks that matters",
                    },
                    speakers: [speaker("anais-figueras")],
                  },
                  {
                    hour: "17.30h",
                    id: "subitem3",
                    name: {
                      es: "Podcast",
                      en: "Podcast",
                      ca: "Podcast",
                    },
                    speakers: [speaker("ma-jesus-espinosa-de-los-monteros")],
                  },
                  {
                    hour: "19:00h",
                    id: "subitem4",
                    name: networking,
                  },
                ],
              },
              {
                date: {
                  es: "Viernes, 17 de mayo",
                  en: "Friday, May 17th",
                  ca: "Divendres, 17 de maig",
                },
                subitems: [
                  {
                    hour: "16:00h",
                    id: "subitem5",
                    name: {
                      es: "Negocio y Linkedin",
                      en: "Linkedin",
                      ca: "Linkedin",
                    },
                    speakers: [speaker("rosario-sierra")],
                  },
                  {
                    hour: "17.00h",
                    id: "subitem6",
                    name: {
                      es: "Creatividad",
                      en: "Creativity",
                      ca: "Creativitat",
                    },
                    speakers: [speaker("sebastian-sanchez")],
                  },
                  {
                    hour: "19:15h",
                    id: "subitem7",
                    name: networking,
                  },
                ],
              },
              {
                date: {
                  es: "Sábado, 18 de mayo",
                  en: "Saturday, May 18th",
                  ca: "Dissabte, 18 de maig",
                },
                subitems: [
                  {
                    hour: "16:00h",
                    id: "subitem8",
                    name: {
                      es: "Contenidos",
                      en: "Content",
                      ca: "Continguts",
                    },
                    speakers: [speaker("gonzalo-figari")],
                  },
                  {
                    hour: "17:00h",
                    id: "subitem11",
                    name: {
                      es: "Debate: ¿Contenido o creatividad?",
                      en: "Talk: Content or creativity?",
                      ca: "Debat: Contingut? o Creativitat?",
                    },
                  },
                  {
                    hour: "19:00h",
                    id: "subitem12",
                    name: networking,
                  },
                ],
              },
              {
                date: {
                  es: "Domingo, 19 de mayo",
                  en: "Sunday, May 19th",
                  ca: "Diumenge, 19 de maig",
                },
                subitems: [
                  {
                    hour: "12:00h",
                    id: "subitem9",
                    name: {
                      es: "Estrategia",
                      en: "Strategy",
                      ca: "Estratègia",
                    },
                    speakers: [speaker("sergio-mendez")],
                  },
                  {
                    hour: "13:00",
                    id: "subitem10",
                    name: {
                      es: "Marketing Experiencial",
                      en: "Marketing Experience",
                      ca: "Marketing Experiencial",
                    },
                    speakers: [speaker("oscar-ordoñez")],
                  },
                  {
                    hour: "13:00h",
                    id: "subitem13",
                    name: {
                      es: "Clausura Formentera20",
                      en: "Formentera20 Closing",
                      ca: "Clausura Formentera20",
                    },
                    description: {
                      es:
                        "* El orden y horarios de las presentaciones puede variar por causas ajenas a la organización.<br><br>Se enviará por mail días antes del evento.<br><br>¡Gracias por seguirnos!<br><br>DesconectarParaConectar = Formentera20<br><br>www.formentera20.com",
                      en:
                        "* The order of the presentations may vary. <br><br>Information + OFF Activities and locations we will send you days before the event by email. <br><br> Thanks for following us!<br><br>DisconectToConect = Formentera20<br><br>www.formentera20.com",
                      ca: `*  L'ordre i horaris de les presentacions pot variar per causes alienes a l'organització.<br><br>Informació i activitats us les enviarem per mail dies abans de l’esdeveniment.<br><br>Gràcies per seguir-nos!<br><br>Formentera20 = DesconnectarPerConnectar<br><br>www.formentera20.com`,
                    },
                  },
                ],
              },
            ],
          },
        },
      ],
    },
    footer,
  ],
};

export default data;
